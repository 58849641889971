import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { saveProjectCompleteStart } from '../store/actions/ProjectActions';

const CompleteProjectModal = (props) => {
    const { selectedProjectId, dispatch } = props;

    const handleCompleteProject = (values) => {
        let newValue = { ...values, project_id: selectedProjectId };
        dispatch(saveProjectCompleteStart(newValue));
    }

    const validationSchema = Yup.object().shape({
        completion_link: Yup.string().required("Completion Link is required *"),
    });

    return (
        <>
            <div id="authModal" className={`overflow-y-scroll show`} >
                <div className="authModalWrapper no-padding">
                    <div className={`modal-body form-wrapper form--dark col-lg-4 col-md-6 col-xs-10 col-sm-10 show`}>
                        <div className="outside-scroll"></div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  no-padding ">
                            <div className="headerwrapper mb-3">
                                <h4 className="text-center text-capitalize">Complete Project</h4>
                                <div
                                    className="modal-close"
                                    onClick={() => props.handleCompleteProjectModal(null)}
                                >
                                    <svg className="woox-icon">
                                        <use xlinkHref="#icon-error-circle"></use>
                                    </svg>
                                </div>
                            </div>
                            <Formik
                                initialValues={{
                                    completion_link: "",
                                }}
                                validationSchema={validationSchema}
                                onSubmit={(values) => {
                                    handleCompleteProject(values);
                                }}>
                                {({
                                    touched,
                                    errors,
                                    isSubmitting,
                                    resetForm,
                                    isValidating,
                                    setFieldValue,
                                }) => (
                                    <Form noValidate className="form--search form--search-transparent w-100 mt-4 mb-4 ">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb30">
                                                <label
                                                    htmlFor="completion_link"
                                                    className="custom-label mb-3 ml-0 text-capitalize">
                                                    Completion Link{" "}
                                                    <abbr className="required" title="required">
                                                        *
                                                    </abbr>
                                                </label>
                                                <Field
                                                    id="completion_link"
                                                    className={`no-padding form-control ${touched.completion_link && errors.completion_link
                                                        ? "is-invalid"
                                                        : ""
                                                        }`}
                                                    name="completion_link"
                                                    placeholder="Completion Link"
                                                    type="text"
                                                />
                                                <ErrorMessage
                                                    component="div"
                                                    name="completion_link"
                                                    className="invalid-feedback mt-3"
                                                />
                                            </div>
                                        </div>
                                        <div className="custom-hr mb-3"></div>
                                        <div className="buttoncancelsavewrapper mt-3">
                                            <button
                                                type="submit"
                                                className="btn btn--medium btn--primary text-capitalize"
                                                disabled={props.saveProject.buttonDisable}
                                            >
                                                {props.saveProject.loadingButtonContent ? props.saveProject.loadingButtonContent : "complete project"}
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

const mapStateToPros = (state) => ({
    saveProject: state.projectReducer.saveProject,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CompleteProjectModal);