import React from "react";
import { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import { fetchInvestedProjectsStart } from "../../store/actions/ProjectActions";
import "react-circular-progressbar/dist/styles.css";
import configuration from "react-global-configuration";
import { Link } from "react-router-dom";
import { authContext } from "../auth/AuthProvider";
import Web3 from "web3";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../../Helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import InvestedProjectTableRow from "./InvestedProjectTableRow";
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';

const InvestedProjects = (props) => {

  const { auth } = useContext(authContext);
  const [take, setTake] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (auth.accounts != "" && !auth.loading) {
      props.dispatch(fetchInvestedProjectsStart({ skip: 0, take: take }));
      window.web3 = new Web3(window.ethereum);
    }
  }, [auth.accounts, auth.loading]);

  useEffect(() => {
    if (!props.investedproject.loading && props.investedproject.data.total) {
      setPageCount(props.investedproject.data.total / take);
    }
  }, [props.investedproject]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [currentPage]);

  const copyToClipboard = (data) => {
    navigator.clipboard.writeText(data);
    const notificationMessage = getSuccessNotificationMessage(
      "text copied to clipboard"
    );
    props.dispatch(createNotification(notificationMessage));
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
    let skip = take * event.selected;
    props.dispatch(fetchInvestedProjectsStart({ skip: skip, take: take }));
  }

  return (
    <>
      {props.investedproject.loading ? (
        "loading"
      ) : (
        <div id="invested-projects">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb30 no-padding">
            <div className="tableHeadingcustom d-flex justify-content-between">
              <h4 className="text-al secondary-text">Invested Projects</h4>
            </div>
            <div className="customtableWrapper">
              {props.investedproject.data.total > 0 ? (
                <>
                  <div className="customtableWrapper">
                    <table id="customTable" className="singleProejectsoon">
                      <thead></thead>
                      <thead>
                        <tr>
                          <th>Logo</th>
                          <th>Project</th>
                          <th>Staked Token</th>
                          {/* <th>Unstaked Token</th>
                        <th>Wallet Address</th> */}
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.investedproject.data.total > 0 &&
                          props.investedproject.data.invested_projects.map(
                            (invested_project, index) => (
                              <>{invested_project.project &&
                                <InvestedProjectTableRow
                                  key={index}
                                  invested_project={invested_project}
                                />
                              }
                              </>
                            )
                          )}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex justify-content-center mt-5">
                    <p>You haven't invested in any projects. </p>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="/projects"
                      className="btn btn--large  btn--transparent btn--primary ml-3 text-capitalize secondry-button"
                    >
                      Start investing
                    </Link>
                  </div>{" "}
                </>
              )}
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                forcePage={currentPage}
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  investedproject: state.projectReducer.investedproject,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(InvestedProjects);
