import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import moment from 'moment';
import { fetchSingleOwnProjectsStart } from '../store/actions/ProjectActions'
import { useParams, useHistory } from 'react-router';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import { addProjectStart } from '../store/actions/ProjectActions'
import { Link } from 'react-router-dom';
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";

var today = new Date();
today.setDate(today.getDate());

const now = new Date();
const disablePrevious = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);

const yesterdayBegin = new Date(Date.UTC(0, 0, 0, 0, 0, 0));
const todayNoon = new Date(Date.UTC(0, 0, 0, 0, 0, 0));

const EditProject = (props) => {

  const { id } = useParams();

  const history = useHistory();

  const [projectInputDetails, setProjectInputDetails] = useState({})

  useEffect(() => {
    props.dispatch(fetchSingleOwnProjectsStart({ project_unique_id: id }))
  }, [])

  useEffect(() => {
    if (!props.editProject.loading) {

      // converting date and time to timestamp for calender do not edit 

      const startTime = moment(props.editProject.data.project.start_time).format("DD,MM,YYYY,HH,mm,ss,a");

      const endTime = moment(props.editProject.data.project.end_time).format("DD,MM,YYYY,HH,mm,ss");

      const startTimeArray = startTime.split(',');
      const endTimeArray = endTime.split(',')
      const offset = new Date().getTimezoneOffset() / -60;
      const offsetArray = offset.toString().split('.');

      const offsetHour = parseInt(offsetArray[0]);

      const offsetMinute = parseInt(offsetArray[1]) * 6;

      const startTimeStamp = new Date(Date.UTC(startTimeArray[2], startTimeArray[1], startTimeArray[0], startTimeArray[3] - offsetHour, startTimeArray[4] - offsetMinute))

      const endTimeStamp = new Date(Date.UTC(endTimeArray[2], endTimeArray[1], endTimeArray[0], endTimeArray[3] - offsetHour, endTimeArray[4] - offsetMinute,))

      setProjectInputDetails({
        name: props.editProject.data.project.name,
        from_wallet_address: props.editProject.data.project.from_wallet_address,
        total_tokens: props.editProject.data.project.total_tokens,
        image: props.editProject.data.project.picture,
        description: props.editProject.data.project.description,
        website: props.editProject.data.project.website,
        twitter_link: props.editProject.data.project.twitter_link,
        facebook_link: props.editProject.data.project.facebook_link,
        telegram_link: props.editProject.data.project.telegram_link,
        medium_link: props.editProject.data.project.medium_link,
        start_time: startTime,
        end_time: endTime,
        project_id: props.editProject.data.project.project_id,
      });

      onChange([startTimeStamp, endTimeStamp])
    }
  }, [props.editProject.loading])

  useEffect(() => {

    // redirect after edit success

    if (props.addProject.editStatus) {
      history.push({
        pathname: "/account/own-projects",
        state: {
          activeIndex: 4
        }
      })
    }

  }, [props.addProject.loading])

  const hanldeProjectInputDetails = (event) => {
    setProjectInputDetails({
      ...projectInputDetails,
      [event.target.name]: event.target.value
    })
  }

  const handleChangeImage = (event) => {
    if (event.currentTarget.type === "file") {
      setProjectInputDetails({
        ...projectInputDetails,
        [event.currentTarget.name]: event.currentTarget.files[0],
      });

      let reader = new FileReader();
      let file = event.currentTarget.files[0];

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  const [value, onChange] = useState([yesterdayBegin, todayNoon]);

  const handleEditProject = (values) => {
    console.log(values);

    const startTime = moment(value[0]).format("DD-MM-YYYY HH:MM:SS");
    const endTime = moment(value[1]).format("DD-MM-YYYY  HH:MM:SS");

    let newProjectDetails = { ...values, start_time: startTime, end_time: endTime };
    if (values.image !== props.editProject.data.project.picture) {
      newProjectDetails = { ...newProjectDetails, picture: values.image }
    }

    props.dispatch(addProjectStart(newProjectDetails))
  }

  const addProjectSchema = Yup.object().shape({
    name: Yup.string().required("Project Name is required *"),
    from_wallet_address: Yup.string().required("Project Owner Wallet Address is required *"),
    total_tokens: Yup.number().required("Total Tokens is required *"),
    description: Yup.string().required("Description is required *"),
    image: Yup.mixed().required("Image is Required *"),
    website: Yup.string().required("Website is required *"),
    twitter_link: Yup.string(),
    medium_link: Yup.string(),
  });

  return (
    <>
      <div className="other_page_layouts">
        <section className="main-content-wrapper">
          <div className="container-fluid">
            <div className="row w-100 no-margin">
              <div className="makeit-center no-padding col-lg-12 col-md-12 col-xs-12 col-sm-12">
                <div className="col-lg-11 col-md-11 col-xs-12 col-sm-12 no-padding">
                  <div className="EditprojectWrapper">
                    <div className="headerwrapper">
                      <h4 className="text-center text-capitalize">Edit Project</h4>
                    </div>
                    {props.editProject.loading ? "loading" :
                      <Formik
                        initialValues={projectInputDetails}
                        validationSchema={addProjectSchema}
                        onSubmit={(values) => {
                          handleEditProject(values);
                        }}
                      >
                        {({
                          touched,
                          errors,
                          isSubmitting,
                          resetForm,
                          isValidating,
                          setFieldValue,
                        }) => (
                          <Form className="form--search form--search-transparent w-100 mt-4 mb-4 ">
                            <div className="row">
                              {/* <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb30">
                              <label htmlFor="username" className="custom-label mb-3 ml-0 text-capitalize">user name *</label>
                              <input
                                id="username"
                                className=" no-padding"
                                name="name" 
                                placeholder="username"
                                type="text"
                                onChange={(event) => hanldeProjectInputDetails(event)}
                                value={projectInputDetails.name}
                              />
                            </div> */}
                              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb30 addproject-between">
                                <label
                                  htmlFor="ProjectName"
                                  className="custom-label ml-0 text-capitalize primary-text"
                                >
                                  Project Name{" "}
                                  <abbr className="required" title="required">
                                    *
                                  </abbr>
                                </label>
                                <Field
                                  id="ProjectName"
                                  className={`no-padding form-control ${touched.name && errors.name ? "is-invalid" : ""
                                    }`}
                                  name="name"
                                  placeholder="Project Name"
                                  type="text"
                                // onChange={(event) => hanldeProjectInputDetails(event)}
                                // value={projectInputDetails.name}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="name"
                                  className="invalid-feedback mt-3"
                                />
                              </div>

                              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb30 addproject-between">
                                <label
                                  htmlFor="ProjectOwnerWalletAddress"
                                  className="custom-label ml-0 text-capitalize primary-text"
                                >
                                  Project Owner Wallet Address{" "}
                                  <abbr className="required" title="required">
                                    *
                                  </abbr>
                                </label>
                                <Field
                                  id="ProjectOwnerWalletAddress"
                                  className={`no-padding form-control ${touched.from_wallet_address && errors.from_wallet_address ? "is-invalid" : ""
                                    }`}
                                  name="from_wallet_address"
                                  placeholder="Project Owner Wallet Address"
                                  type="text"
                                />
                                <ErrorMessage
                                  component="div"
                                  name="from_wallet_address"
                                  className="invalid-feedback mt-3"
                                />
                              </div>

                              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb30 addproject-between">
                                <label
                                  htmlFor="date"
                                  className="custom-label ml-0  text-capitalize"
                                >
                                  Start & End Date{" "}
                                  <abbr className="required" title="required">
                                    *
                                  </abbr>
                                </label>
                                <div
                                  id="customReactDateTimeCalender"
                                  className="w-100"
                                >
                                  {/* <DatePicker   
                                startDate={date.startDate}
                                endDate={date.endDate}
                                onChange={onRangeChange}
                                minDate={today}
                                selectsRange  
                              /> */}
                                  <DateTimeRangePicker
                                    onChange={onChange}
                                    value={value}
                                    format={"dd-M-y h:mm:ss a"}
                                    minDate={yesterdayBegin}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb30 addproject-between">
                                <label
                                  htmlFor="TotalTokens"
                                  className="custom-label ml-0  text-capitalize"
                                >
                                  Total Tokens Required{" "}
                                  <abbr className="required" title="required">
                                    *
                                  </abbr>
                                </label>
                                <Field
                                  id="TotalTokens"
                                  className={`no-padding form-control ${touched.total_tokens && errors.total_tokens
                                    ? "is-invalid"
                                    : ""
                                    }`}
                                  name="total_tokens"
                                  placeholder="Total Tokens"
                                  type="number"
                                // onChange={(event) => hanldeProjectInputDetails(event)}
                                // value={projectInputDetails.total_tokens}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="total_tokens"
                                  className="invalid-feedback mt-3"
                                />
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb30">
                                <label
                                  htmlFor="picture"
                                  className="custom-label ml-0 text-capitalize"
                                >
                                  Select Picture{" "}
                                  <abbr className="required" title="required">
                                    *
                                  </abbr>
                                </label>
                                <input
                                  id="picture"
                                  className={`no-padding form-control ${touched.image && errors.image
                                    ? "is-invalid"
                                    : ""
                                    }`}
                                  name="image"
                                  placeholder="Select Picture"
                                  type="file"
                                  accept="image/*"
                                  // onChange={(event) => handleChangeImage(event)}
                                  onChange={(event) => {
                                    setFieldValue("image", event.target.files[0]);
                                  }}
                                />
                              </div>
                              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb30">
                                <label
                                  htmlFor="desc"
                                  className="custom-label ml-0 text-capitalize"
                                >
                                  Description{" "}
                                  <abbr className="required" title="required">
                                    *
                                  </abbr>
                                </label>
                                <Field
                                  id="desc"
                                  className={`no-padding form-control ${touched.description && errors.description
                                    ? "is-invalid"
                                    : ""
                                    }`}
                                  name="description"
                                  placeholder="Description"
                                  type="text"
                                  as="textarea"
                                // onChange={(event) => hanldeProjectInputDetails(event)}
                                // value={projectInputDetails.description}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="description"
                                  className="invalid-feedback mt-3"
                                />
                              </div>
                            </div>
                            <h5 className="text-muted text-capitalize mt-3 mb-3 letter-2">
                              Social Settings
                            </h5>
                            <div className="custom-hr"></div>
                            <div className="row">
                              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb30">
                                <label
                                  htmlFor="Website"
                                  className="custom-label mb-3 ml-0 text-capitalize"
                                >
                                  Website{" "}
                                  <abbr className="required" title="required">
                                    *
                                  </abbr>
                                </label>
                                <Field
                                  id="Website"
                                  className={`no-padding form-control ${touched.website && errors.website
                                    ? "is-invalid"
                                    : ""
                                    }`}
                                  name="website"
                                  placeholder="Website"
                                  type="text"
                                // onChange={(event) => hanldeProjectInputDetails(event)}
                                // value={projectInputDetails.website}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="website"
                                  className="invalid-feedback mt-3"
                                />
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb30">
                                <label
                                  htmlFor="TelegramLink"
                                  className="custom-label mb-3 ml-0 text-capitalize"
                                >
                                  Telegram Link
                                </label>
                                <Field
                                  id="TelegramLink"
                                  className={`no-padding form-control ${touched.telegram_link && errors.telegram_link
                                    ? "is-invalid"
                                    : ""
                                    }`}
                                  name="telegram_link"
                                  placeholder="Telegram Link"
                                  type="text"
                                // onChange={(event) => hanldeProjectInputDetails(event)}
                                // value={projectInputDetails.telegram_link}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="telegram_link"
                                  className="invalid-feedback mt-3"
                                />
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb30">
                                <label
                                  htmlFor="Medium"
                                  className="custom-label mb-3 ml-0 text-capitalize"
                                >
                                  Medium Link
                                </label>
                                <Field
                                  id="Medium"
                                  className={`no-padding form-control ${touched.medium_link && errors.medium_link
                                    ? "is-invalid"
                                    : ""
                                    }`}
                                  name="medium_link"
                                  placeholder="Medium Link"
                                  type="text"
                                // onChange={(event) => hanldeProjectInputDetails(event)}
                                // value={projectInputDetails.medium_link}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="medium_link"
                                  className="invalid-feedback mt-3"
                                />
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb30">
                                <label
                                  htmlFor="Facebook"
                                  className="custom-label mb-3 ml-0 text-capitalize"
                                >
                                  Facebook Link
                                </label>
                                <Field
                                  id="Facebook"
                                  className={`no-padding form-control ${touched.facebook_link && errors.facebook_link
                                    ? "is-invalid"
                                    : ""
                                    }`}
                                  name="facebook_link"
                                  placeholder="Facebook Link"
                                  type="text"
                                // onChange={(event) => hanldeProjectInputDetails(event)}
                                // value={projectInputDetails.facebook_link}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="facebook_link"
                                  className="invalid-feedback mt-3"
                                />
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb30">
                                <label
                                  htmlFor="Twitter"
                                  className="custom-label mb-3 ml-0 text-capitalize"
                                >
                                  Twitter Link
                                </label>
                                <Field
                                  id="Twitter"
                                  className={`no-padding form-control ${touched.twitter_link && errors.twitter_link
                                    ? "is-invalid"
                                    : ""
                                    }`}
                                  name="twitter_link"
                                  placeholder="Twitter Link"
                                  type="text"
                                // onChange={(event) => hanldeProjectInputDetails(event)}
                                // value={projectInputDetails.twitter_link}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="twitter_link"
                                  className="invalid-feedback mt-3"
                                />
                              </div>
                            </div>
                            <div className="buttoncancelsavewrapper mt-3">
                              <button
                                type="reset"
                                className="btn btn--medium btn--transparent btn--primary"
                                // onClick={handleResetInput}
                                onClick={resetForm}
                              >
                                reset
                              </button>
                              <button
                                type="submit"
                                className="btn btn--medium btn--primary"
                                // onClick={handleAddProject}
                                disabled={props.addProject.buttonDisable}
                              >
                                {props.addProject.loadingButtonContent !== null
                                  ? props.addProject.loadingButtonContent
                                  : "save"}
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

const mapStateToPros = (state) => ({
  editProject: state.projectReducer.editProject,
  addProject: state.projectReducer.addProject,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(EditProject)
