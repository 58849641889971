import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import {
  addProjectStart,
  sendProjectTokenAdminRestart,
  resetAddProjectData,
  projectPoolContractSaveStart
} from "../store/actions/ProjectActions";
import { connect } from "react-redux";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import Web3 from "web3";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../Helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import configuration from "react-global-configuration";
import StakingPool from "../../abis/StakingPool.json";
import { authContext } from "../account/auth/AuthProvider";

var today = new Date();
today.setDate(today.getDate());

const now = new Date();
const yesterdayBegin = new Date(
  now.getFullYear(),
  now.getMonth(),
  now.getDate() + 1
);
const todayNoon = new Date(
  now.getFullYear(),
  now.getMonth(),
  now.getDate() + 2
);

const AddProjectModal = (props) => {

  const { auth } = useContext(authContext);

  const [contractIsValid, setContractIsValid] = useState(false);

  const [deployBtn, setDeployBtn] = useState({
    loading: false,
    content: ""
  });

  // const [stakingPool, setStakingPool] = useState("");

  const { status } = props;

  const [value, onChange] = useState([yesterdayBegin, todayNoon]);

  const [projectInputDetails, setProjectInputDetails] = useState({
    name: "",
    from_wallet_address: "",
    total_tokens: "",
    allowed_tokens: "",
    exchange_rate: "",
    token_symbol: "",
    picture: "",
    contract_address: "",
    description: "",
    website: "",
    twitter_link: "",
    facebook_link: "",
    telegram_link: "",
    medium_link: "",
    start_time: "",
    end_time: "",
    decimal_points: "",
  });

  const handleAddProject = (values) => {

    const startTime = moment(value[0]).format("DD-MM-YYYY HH:MM:SS");
    const endTime = moment(value[1]).format("DD-MM-YYYY  HH:MM:SS");

    const newProjectDetails = {
      ...values,
      start_time: startTime,
      end_time: endTime,
    };

    setDeployBtn({
      ...deployBtn,
      loading: true,
      content: "Loading..."
    });

    props.dispatch(addProjectStart(newProjectDetails));
  };

  const [skipinitalRender, setSkipInitialRender] = useState(true);

  useEffect(() => {
    props.dispatch(sendProjectTokenAdminRestart());
    props.dispatch(sendProjectTokenAdminRestart());
  }, []);

  const delopyProject = async () => {

    setDeployBtn({
      ...deployBtn,
      loading: true,
      content: "Deploying Contract..."
    });

    try {
      const web3 = window.web3;

      const networkId = await web3.eth.net.getId();

      const stakingPool = await new web3.eth.Contract(StakingPool.abi);

      console.log("Deploy project")

      let _amount = props.addProject.data.total_tokens.toString();
      _amount = window.web3.utils.toWei(_amount, "Ether");

      console.log("Deploy amount ", _amount)

      const res = await stakingPool
        .deploy({
          data: StakingPool.bytecode,
          arguments: [
            configuration.get("configData.lp_contract_address"),
            props.addProject.data.name,
            configuration.get("configData.admin_wallet_address"),
            _amount,
            props.addProject.data.from_wallet_address,
          ],
        })
        .send(
          {
            from: auth.accounts,
          },
          function (error, transactionHash) {
            // API call....
            if (!transactionHash) {
              setDeployBtn({
                ...deployBtn,
                loading: false,
                content: ""
              });
            }
          }
        )
        .on("confirmation", (confirmationNumber, receipt) => {
          console.log("con", confirmationNumber);
        })
        .then(async (newContractInstance) => {
          console.log("New token created.", newContractInstance);
          // console.log("transaction_hash", transaction_hash);
          // console.log(
          //   "name",
          //   await newContractInstance.methods.name.call().toString()
          // );
          props.dispatch(
            projectPoolContractSaveStart({
              project_id: props.addProject.data.project_id,
              pool_contract_address: newContractInstance.options.address,
            })
          );
          const notificationMessage = getSuccessNotificationMessage(
            "Contract deployed. Please check the metamask.."
          );
          props.dispatch(createNotification(notificationMessage));
          console.log(newContractInstance.options.address); // instance with the new contract address
          
          // Save the token contract address.
        });
    } catch (error) {
      let notificationMessage = getErrorNotificationMessage(
        error.message
      );
      props.dispatch(createNotification(notificationMessage));
    }
  }

  useEffect(() => {
    if (!skipinitalRender && !props.addProject.loading) {
      if (props.addProject.addStatus) {
        setProjectInputDetails({
          name: "",
          total_tokens: "",
          allowed_tokens: "",
          exchange_rate: "",
          token_symbol: "",
          picture: "",
          contract_address: "",
          description: "",
          website: "",
          twitter_link: "",
          facebook_link: "",
          telegram_link: "",
          medium_link: "",
          start_time: "",
          end_time: "",
          decimal_points: "",
        });
        delopyProject()
      }
      // props.handleAddProjectModal(null);
    }
    setSkipInitialRender(false);
    return () => {
      setSkipInitialRender(true);
    }
  }, [props.addProject.addStatus]);

  useEffect(() => {
    if (!props.addProject.loading && props.addProject.error != false) {
      console.log("Error btn changes")
      setDeployBtn({
        ...deployBtn,
        loading: false,
        content: ""
      });
    }
  }, [props.addProject.error]);

  const addProjectSchema = Yup.object().shape({
    name: Yup.string().required("Project Name is required *"),
    from_wallet_address: Yup.string().required("Project Owner Wallet Address is required *"),
    total_tokens: Yup.number().required("Total Tokens is required *"),
    picture: Yup.mixed().required("Image is Required *"),
    description: Yup.string().required("Description is required *"),
    website: Yup.string().required("Website is required *"),
    twitter_link: Yup.string(),
    medium_link: Yup.string(),
  });

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  useEffect(() => {
    if (!props.poolContractSave.loading && props.poolContractSave.data &&  props.poolContractSave.data.code == 318) {
      setDeployBtn({
        ...deployBtn,
        loading: false,
        content: ""
      });
      props.handleAddProjectModal(null);
    }
  }, [props.poolContractSave.data]);


  return (
    <>
      <div
        id="authModal"
        className={`overflow-y-scroll ${status ? "show" : ""}`}
      >
        <div className="authModalWrapper no-padding">
          <div
            className={`modal-body addProject-modal ${contractIsValid
              ? "col-lg-11 col-md-10 col-xs-11 col-sm-11 "
              : "col-md-10 col-xs-11 col-sm-11"
              } ${status ? "show" : ""}`}
          >
            <div className="headerwrapper">
              <h4 className="text-center text-capitalize primary-text">Add Project</h4>
              <div
                className="modal-close"
                onClick={() => props.handleAddProjectModal(null)}
              >
                <svg className="woox-icon">
                  <use xlinkHref="#icon-error-circle"></use>
                </svg>
              </div>
            </div>
            <div
              className="outside-scroll"
            ></div>
            <Formik
              initialValues={{
                name: projectInputDetails.name,
                from_wallet_address: projectInputDetails.from_wallet_address,
                total_tokens: projectInputDetails.total_tokens,
                allowed_tokens: "",
                exchange_rate: "",
                token_symbol: projectInputDetails.token_symbol,
                picture: "",
                contract_address: "",
                description: "",
                website: "",
                twitter_link: "",
                facebook_link: "",
                telegram_link: "",
                medium_link: "",
                decimal_points: projectInputDetails.decimal_points,
                contract_address: projectInputDetails.contract_address,
              }}
              validationSchema={addProjectSchema}
              onSubmit={(values) => {
                handleAddProject(values);
              }}
            >
              {({
                touched,
                errors,
                isSubmitting,
                resetForm,
                isValidating,
                setFieldValue,
              }) => (
                <Form className="form--search form--search-transparent w-100 mt-4 mb-4 ">
                  <div className="row">
                    {/* <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb30">
                              <label htmlFor="username" className="custom-label mb-3 ml-0 text-capitalize">user name *</label>
                              <input
                                id="username"
                                className=" no-padding"
                                name="name" 
                                placeholder="username"
                                type="text"
                                onChange={(event) => hanldeProjectInputDetails(event)}
                                value={projectInputDetails.name}
                              />
                            </div> */}
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb30 addproject-between">
                      <label
                        htmlFor="ProjectName"
                        className="custom-label ml-0 text-capitalize primary-text"
                      >
                        Project Name{" "}
                        <abbr className="required" title="required">
                          *
                        </abbr>
                      </label>
                      <Field
                        id="ProjectName"
                        className={`no-padding form-control ${touched.name && errors.name ? "is-invalid" : ""
                          }`}
                        name="name"
                        placeholder="Project Name"
                        type="text"
                      // onChange={(event) => hanldeProjectInputDetails(event)}
                      // value={projectInputDetails.name}
                      />
                      <ErrorMessage
                        component="div"
                        name="name"
                        className="invalid-feedback mt-3"
                      />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb30 addproject-between">
                      <label
                        htmlFor="ProjectOwnerWalletAddress"
                        className="custom-label ml-0 text-capitalize primary-text"
                      >
                        Project Owner Wallet Address{" "}
                        <abbr className="required" title="required">
                          *
                        </abbr>
                      </label>
                      <Field
                        id="ProjectOwnerWalletAddress"
                        className={`no-padding form-control ${touched.from_wallet_address && errors.from_wallet_address ? "is-invalid" : ""
                          }`}
                        name="from_wallet_address"
                        placeholder="Project Owner Wallet Address"
                        type="text"
                      />
                      <ErrorMessage
                        component="div"
                        name="from_wallet_address"
                        className="invalid-feedback mt-3"
                      />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb30 addproject-between">
                      <label
                        htmlFor="date"
                        className="custom-label ml-0  text-capitalize"
                      >
                        Start & End Date{" "}
                        <abbr className="required" title="required">
                          *
                        </abbr>
                      </label>
                      <div
                        id="customReactDateTimeCalender"
                        className="w-100"
                      >
                        {/* <DatePicker   
                                startDate={date.startDate}
                                endDate={date.endDate}
                                onChange={onRangeChange}
                                minDate={today}
                                selectsRange  
                              /> */}
                        <DateTimeRangePicker
                          onChange={onChange}
                          value={value}
                          format={"dd-M-y h:mm:ss a"}
                          minDate={yesterdayBegin}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb30 addproject-between">
                      <label
                        htmlFor="TotalTokens"
                        className="custom-label ml-0  text-capitalize"
                      >
                        Total Tokens Required{" "}
                        <abbr className="required" title="required">
                          *
                        </abbr>
                      </label>
                      <Field
                        id="TotalTokens"
                        className={`no-padding form-control ${touched.total_tokens && errors.total_tokens
                          ? "is-invalid"
                          : ""
                          }`}
                        name="total_tokens"
                        placeholder="Total Tokens"
                        type="number"
                      // onChange={(event) => hanldeProjectInputDetails(event)}
                      // value={projectInputDetails.total_tokens}
                      />
                      <ErrorMessage
                        component="div"
                        name="total_tokens"
                        className="invalid-feedback mt-3"
                      />
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb30">
                      <label
                        htmlFor="picture"
                        className="custom-label ml-0 text-capitalize"
                      >
                        Select Picture{" "}
                        <abbr className="required" title="required">
                          *
                        </abbr>
                      </label>
                      <input
                        id="picture"
                        className={`no-padding form-control ${touched.picture && errors.picture
                          ? "is-invalid"
                          : ""
                          }`}
                        name="picture"
                        placeholder="Select Picture"
                        type="file"
                        accept="image/*"
                        // onChange={(event) => handleChangeImage(event)}
                        onChange={(event) => {
                          setFieldValue("picture", event.target.files[0]);
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        name="picture"
                        className="invalid-feedback mt-3"
                      />
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb30">
                      <label
                        htmlFor="desc"
                        className="custom-label ml-0 text-capitalize"
                      >
                        Description{" "}
                        <abbr className="required" title="required">
                          *
                        </abbr>
                      </label>
                      <Field
                        id="desc"
                        className={`no-padding form-control ${touched.description && errors.description
                          ? "is-invalid"
                          : ""
                          }`}
                        name="description"
                        placeholder="Description"
                        type="text"
                        as="textarea"
                      // onChange={(event) => hanldeProjectInputDetails(event)}
                      // value={projectInputDetails.description}
                      />
                      <ErrorMessage
                        component="div"
                        name="description"
                        className="invalid-feedback mt-3"
                      />
                    </div>
                  </div>
                  <h5 className="text-muted text-capitalize mt-3 mb-3 letter-2">
                    Social Settings
                  </h5>
                  <div className="custom-hr"></div>
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb30">
                      <label
                        htmlFor="Website"
                        className="custom-label mb-3 ml-0 text-capitalize"
                      >
                        Website{" "}
                        <abbr className="required" title="required">
                          *
                        </abbr>
                      </label>
                      <Field
                        id="Website"
                        className={`no-padding form-control ${touched.website && errors.website
                          ? "is-invalid"
                          : ""
                          }`}
                        name="website"
                        placeholder="Website"
                        type="text"
                      // onChange={(event) => hanldeProjectInputDetails(event)}
                      // value={projectInputDetails.website}
                      />
                      <ErrorMessage
                        component="div"
                        name="website"
                        className="invalid-feedback mt-3"
                      />
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb30">
                      <label
                        htmlFor="TelegramLink"
                        className="custom-label mb-3 ml-0 text-capitalize"
                      >
                        Telegram Link
                      </label>
                      <Field
                        id="TelegramLink"
                        className={`no-padding form-control ${touched.telegram_link && errors.telegram_link
                          ? "is-invalid"
                          : ""
                          }`}
                        name="telegram_link"
                        placeholder="Telegram Link"
                        type="text"
                      // onChange={(event) => hanldeProjectInputDetails(event)}
                      // value={projectInputDetails.telegram_link}
                      />
                      <ErrorMessage
                        component="div"
                        name="telegram_link"
                        className="invalid-feedback mt-3"
                      />
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb30">
                      <label
                        htmlFor="Medium"
                        className="custom-label mb-3 ml-0 text-capitalize"
                      >
                        Medium Link
                      </label>
                      <Field
                        id="Medium"
                        className={`no-padding form-control ${touched.medium_link && errors.medium_link
                          ? "is-invalid"
                          : ""
                          }`}
                        name="medium_link"
                        placeholder="Medium Link"
                        type="text"
                      // onChange={(event) => hanldeProjectInputDetails(event)}
                      // value={projectInputDetails.medium_link}
                      />
                      <ErrorMessage
                        component="div"
                        name="medium_link"
                        className="invalid-feedback mt-3"
                      />
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb30">
                      <label
                        htmlFor="Facebook"
                        className="custom-label mb-3 ml-0 text-capitalize"
                      >
                        Facebook Link
                      </label>
                      <Field
                        id="Facebook"
                        className={`no-padding form-control ${touched.facebook_link && errors.facebook_link
                          ? "is-invalid"
                          : ""
                          }`}
                        name="facebook_link"
                        placeholder="Facebook Link"
                        type="text"
                      // onChange={(event) => hanldeProjectInputDetails(event)}
                      // value={projectInputDetails.facebook_link}
                      />
                      <ErrorMessage
                        component="div"
                        name="facebook_link"
                        className="invalid-feedback mt-3"
                      />
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb30">
                      <label
                        htmlFor="Twitter"
                        className="custom-label mb-3 ml-0 text-capitalize"
                      >
                        Twitter Link
                      </label>
                      <Field
                        id="Twitter"
                        className={`no-padding form-control ${touched.twitter_link && errors.twitter_link
                          ? "is-invalid"
                          : ""
                          }`}
                        name="twitter_link"
                        placeholder="Twitter Link"
                        type="text"
                      // onChange={(event) => hanldeProjectInputDetails(event)}
                      // value={projectInputDetails.twitter_link}
                      />
                      <ErrorMessage
                        component="div"
                        name="twitter_link"
                        className="invalid-feedback mt-3"
                      />
                    </div>
                  </div>
                  <div className="buttoncancelsavewrapper mt-3">
                    <button
                      type="reset"
                      className="btn btn--medium btn--transparent btn--primary"
                      // onClick={handleResetInput}
                      onClick={resetForm}
                    >
                      reset
                    </button>
                    <button
                      type="submit"
                      className="btn btn--medium btn--primary"
                      // onClick={handleAddProject}
                      disabled={deployBtn.loading}
                    >
                      {deployBtn.content !== ""
                        ? deployBtn.content
                        : "Add Project"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  addProject: state.projectReducer.addProject,
  sendProTokenAdmin: state.projectReducer.sendProTokenAdmin,
  poolContractSave: state.projectReducer.poolContractSave,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AddProjectModal);
