import React, { useState, useContext } from 'react';
import { CircularProgressbar } from "react-circular-progressbar";
import StakingPool from "../../../abis/StakingPool.json";
import { authContext } from "../auth/AuthProvider";
import { updateInvestorProjectStatusStart } from "../../store/actions/ProjectActions";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../../Helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import { formatEther } from "@ethersproject/units";
import { connect } from "react-redux";

const InvestedProjectTableRow = (props) => {

  const { auth } = useContext(authContext);

  const { invested_project } = props;

  const [btn, setBtn] = useState({
    loading: false,
    approveBtnText: "",
    declieBtnText: "",
    data: "",
  });

  const [stakingPool, setStakingPool] = useState("");

  const approveProject = async (project) => {

    try {
      setBtn({
        ...btn,
        loading: true,
        approveBtnText: "Loading...",
      });
      //Approve
      const web3 = window.web3;

      const networkId = await web3.eth.net.getId();

      const stakingPoolData = StakingPool.networks[networkId];
      if (stakingPoolData) {
        const stakingPool = new web3.eth.Contract(
          StakingPool.abi,
          project.pool_contract_address
        );
        setStakingPool(stakingPool);

        console.log("Approve project")
        stakingPool.methods
          .approve()
          .send({ from: auth.accounts })
          .on("receipt", (receipt) => {
            props.dispatch(
              updateInvestorProjectStatusStart({
                investor_status: 1,
                project_id: project.project_id,
              })
            );
            let notificationMessage = getSuccessNotificationMessage(
              "Approve project successfully..."
            );
            props.dispatch(createNotification(notificationMessage));
            setBtn({
              ...btn,
              data: "",
              loading: false,
              approveBtnText: "",
            });
          })
          .on("error", (error) => {
            let notificationMessage;
            if (error.message == undefined) {
              notificationMessage = getErrorNotificationMessage(
                "Unexpected error occuried, Please try again..."
              );
            } else {
              notificationMessage = getErrorNotificationMessage(
                error.message
              );
            }
            props.dispatch(createNotification(notificationMessage));
            setBtn({
              ...btn,
              data: "",
              loading: false,
              approveBtnText: "",
            });
          });
      }
    } catch (error) {
      let notificationMessage = getErrorNotificationMessage(error);
      props.dispatch(createNotification(notificationMessage));
      setBtn({
        ...btn,
        data: "",
        loading: false,
        approveBtnText: "",
      });
    }
  };

  const declineProject = async (project) => {
    setBtn({
      ...btn,
      loading: true,
      declieBtnText: "Loading...",
    });
    //Decline
    const web3 = window.web3;

    const networkId = await web3.eth.net.getId();

    const stakingPoolData = StakingPool.networks[networkId];
    if (stakingPoolData) {
      const stakingPool = new web3.eth.Contract(
        StakingPool.abi,
        project.pool_contract_address
      );
      setStakingPool(stakingPool);

      console.log("Decline project")
      stakingPool.methods
        .decline()
        .send({ from: auth.accounts })
        .on("receipt", (receipt) => {
          props.dispatch(
            updateInvestorProjectStatusStart({
              investor_status: 1,
              project_id: project.project_id,
            })
          );
          let notificationMessage = getSuccessNotificationMessage(
            "Approve project successfully..."
          );
          props.dispatch(createNotification(notificationMessage));
          setBtn({
            ...btn,
            data: "",
            loading: false,
            declieBtnText: "",
          });
        })
        .on("error", (error) => {
          let notificationMessage;
          if (error.message == undefined) {
            notificationMessage = getErrorNotificationMessage(
              "Unexpected error occuried, Please try again..."
            );
          } else {
            notificationMessage = getErrorNotificationMessage(
              error.message
            );
          }
          props.dispatch(createNotification(notificationMessage));
          setBtn({
            ...btn,
            data: "",
            loading: false,
            declieBtnText: "",
          });
        });
    }
  };

  return (
    <tr>
      <td>
        <img
          className="logo"
          src={invested_project.project.picture}
          alt={invested_project.name}
        />
      </td>
      <td className="text-capitalize text-center">
        {invested_project.project.name}
        {invested_project.project.completion_link && (
          <div>
            <a className="btn btn--primary text-capitalize mt-2" href={invested_project.project.completion_link} target="_blank">View Site</a>
          </div>
        )}
      </td>
      <td className="text-capitalize text-center">
        {Number(
          formatEther(
            invested_project.stacked
          )
        ).toFixed(2)}
      </td>
      {/* <td className="text-capitalize text-center">
                                {invested_project.unstacked_formatted}
                              </td>
                              <td className="text-capitalize text-center">
                                <button
                                  onClick={() =>
                                    copyToClipboard(
                                      invested_project.wallet_address
                                    )
                                  }
                                  className="copy-to-clip-btn"
                                >
                                  {invested_project.wallet_address}
                                </button>
                              </td> */}
      <td className="greenText text-capitalize text-center">
        {invested_project.project.publish_status}
      </td>
      <td className="greenText text-capitalize text-center">
        <div className="projectActionbtn">
          {invested_project.project.publish_status != "closed" || invested_project.investor_status != 0 ? (
            "-"
          ) : (
            <>
              <button
                type="button"
                className="buttonwrapper btn--transparent approve-project-btn"
                onClick={() =>
                  approveProject(invested_project.project)
                }
                disabled={btn.loading}
              >
                <i class="fa fa-check"></i>
                {btn.approveBtnText === "" ? "Approve" : btn.approveBtnText}
              </button>
              <button
                type="button"
                className="buttonwrapper btn--transparent decline-project-btn ml-2"
                onClick={() =>
                  declineProject(invested_project.project)
                }
                disabled={btn.loading}
              >
                <i class="fa fa-ban"></i>
                {btn.declieBtnText === "" ? "Decline" : btn.declieBtnText}
              </button>
            </>
          )}
        </div>
      </td>
    </tr>
  );
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(InvestedProjectTableRow);